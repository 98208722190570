<template>
  <div>
  <b-card class="blog-edit-wrapper" :title="tituloForm">
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <!-- fecha de publicacion -->
          <b-col cols="4">
            <b-form-group label-for="Fecha Publicacion">
              <label >Fecha Publicación <span class="text-danger">(*)</span></label>
              <flat-pickr
                v-model="published_at"
                class="form-control"                
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', locale: 'es' }"
              />
              <small
                v-for="error in errores.published_at"
                :key="error"
                class="text-danger"
                >{{ error }}</small
              >
            </b-form-group>
          </b-col>
          <!-- titulo -->
          <b-col cols="12">
            <b-form-group
                label-for="Titulo"
            >
                <label >Título <span class="text-danger">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Titulo"
                  rules="required"
                  >
                  <b-form-input
                      id="titulo"
                      v-model="titulo"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Titulo"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small v-for="error in errores.titulo" :key="error" class="text-danger">{{ error }}</small>
                </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="Descripcion"
                label-for="Descripcion"
            >
              <!--<CkEditorPersonalizado
                ref="ckeditorDescripcion"
                v-model="descripcion"
              >
              </CkEditorPersonalizado>-->

              <quill-editor
                style="border: 1px solid #ccc; border-top-right-radius: 0.357rem; border-top-left-radius: 0.357rem;"
                id="quil-content"
                v-model="descripcion"
                :options="editorOption"
                class="border-bottom-0"
              />
              <div
                id="quill-toolbar"
                class="d-flex justify-content-end border-top-0"
              >
                <!-- Add a bold button -->
                <button class="ql-bold" />
                <button class="ql-italic" />
                <button class="ql-underline" />
                <button class="ql-link" />
              </div>
              <!--<b-form-textarea
                id="textarea-default"
                v-model="descripcion"
                placeholder=""
                rows="3"
                />-->
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-overlay :show="loading">
          <b-row>
              <b-col cols="12" class="mb-1">
                  <b-button
                      size="sm"
                      class="mr-1"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-primary" @click="seleccionarImagenes"
                      >
                      Subir Imagenes
                  </b-button>
                  <b-button
                      v-b-modal.modal-seleccionar-imagen
                      size="sm"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-primary"
                      >
                      Elegir Imagen
                  </b-button>
                  <b-form-file
                      @change="subirImagenes"
                      v-model="imagenes"
                      accept="image/*"
                      class="mt-1"
                      plain
                      multiple
                      hidden
                      id="imageUpload"
                  />
              </b-col>
              <b-col cols="12">
                  <draggable
                    v-model="items"
                    class="cursor-move row border"
                  >
                    <b-col
                        v-for="(item, index) in items" :key="index"
                        sm="6"
                        md="4"
                        lg="4"
                        cols="12"
                    >
                        <b-card
                            :img-src="item.url"
                            no-body
                            img-top
                            img-alt="card img"
                            :header="item.titulo"
                        >
                            <b-button @click="removerImagen(index)" variant="flat-danger" size="sm">
                              <span class="align-middle">Remover</span>
                            </b-button>
                        </b-card>
                    </b-col>
                  </draggable>
              </b-col>
          </b-row>
        </b-overlay>
        <!-- submit -->
        <b-row>
          <b-col cols="12" class="mt-50">
            <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              split
              text="Guardar"
              variant="primary"
              class="mr-1"
              @click="validar(),guardarSalir=true"
              right
            >
              <b-dropdown-item @click="validar(),guardarSalir=false"> 
                Guardar y Editar
              </b-dropdown-item>
            </b-dropdown>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              :to="{ name: 'galeria' }"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!--Componente Imagen -->
    <ImagenComponent @enviarImagen="recibirImagen"></ImagenComponent>
  </b-card>
  </div>
</template>
<script>
import axiosIns from '@/libs/axios'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import es from "vee-validate/dist/locale/es.json";
import {
  BCard, BCardText, BButton, BRow, BCol, BCardBody, BCardTitle, BCardSubTitle, BLink, BFormGroup, BFormInput, BForm, BFormTextarea, BImg, BFormFile, BDropdown, BDropdownItem, BContainer, BOverlay, BListGroupItem
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import Ripple from 'vue-ripple-directive'
import { required, length } from '@validations';
import ImagenComponent from '@/componentes/ImagenComponent.vue';
import CkEditorPersonalizado from "@/componentes/CkEditorPersonalizado.vue";
import { quillEditor } from 'vue-quill-editor'
import flatPickr from "vue-flatpickr-component";

const Spanish = require('flatpickr/dist/l10n/es');
localize("es", es);

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BImg, 
    BFormFile,
    BContainer,
    BOverlay,
    BDropdown, BDropdownItem,
    ImagenComponent,
    draggable,
    BListGroupItem,
    CkEditorPersonalizado,
    quillEditor,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data () {
      return {
          //datos de entrada
          id:null,
          titulo:'',
          descripcion:'',
          published_at: null,
          items:[],
          required,
          submitted: false,
          guardarSalir:true,
          errores:[],
          imagenes:[],
          imagen:[],
          loading: false,
          editorOption :{
            modules: {
              toolbar: '#quill-toolbar',
            },
            placeholder:'Descripcion ...'
          }
      }
  },

  computed: {
    tituloForm() {
      return this.id ? "Editar Galeria" : "Nueva Galería";
    },
  },
  watch: {
    $route: function(val) {
        if (val.params.id) {
          this.editar(this.$route.params.id);
        }else{
          this.limpiar();
        }
    },
  },
  created(){        
    this.date();
  },  
  destroyed() {
 
  },
  methods: {
    date() {
      const hoy = new Date();
      var date =
        hoy.getFullYear() + "-" + (hoy.getMonth() + 1) + "-" + hoy.getDate();
      var time = hoy.getHours() + ":" + hoy.getMinutes();
      this.published_at = date + " " + time;
    },
    validar() {
        this.$refs.simpleRules.validate().then(success => {
            if (success) {
                if (!this.submitted) {
                  this.submitted = true;
                  if (this.id) {
                    this.actualizar();
                  }else{
                    this.registrar();
                  }
                }
            }
        })
    },

    async subirImagenes (event) {
        this.loading = true;
        if (event.target.files.length) {
            let file = event.target.files;
            let formData = new FormData();
            formData.append('tipo', 'IMAGEN');
            formData.append('mediosCategoria_id', 1);
            formData.append('enlace', 0);
            for(let i = 0 ; i < file.length ; i++){
                formData.append('imagenes[]', file[i]);
            }
            await axiosIns.post('/admin/subir-imagenes',
            formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })
            .then(res => {
              this.loading = false;
              let resItem = res.data.map(g => ({
                  url:g.url,
                  titulo:g.titulo,
                  index:'',
              }));
              this.items = this.items.concat(resItem);
              this.imagenes=[];
              this.errores=[];
            })
            .catch(err =>{
              this.loading = false;
            });
        }
    },

    seleccionarImagenes(){
        document.getElementById("imageUpload").click()
    },

    removerImagen(index){
     this.items.splice(index,1);
    },

    recibirImagen(imagen){
      let resItem={url:imagen.url,titulo:imagen.titulo,index:''}
      this.items = this.items.concat(resItem);
    },

    //REGISTRO DE GALERIA
    registrar() {
      for(let i = 0 ; i < this.items.length ; i++){
        this.items[i].index=i;
      }
      axiosIns.post('/admin/galeria',{
          'titulo':this.titulo,
          'descripcion':this.descripcion,
          'published_at': this.published_at,
          'items':this.items,
      })
      .then(res => {
        this.$bvToast.toast('Exito', {
          title: 'Galeria de guardo con exito',
          variant:'primary',
          solid: false,
        })
        if (this.guardarSalir) {
          this.$router.push({ name: "galeria" });
        }else{
          this.id=res.data
          this.$router.push({ name: "galeria-editar",params: { id: res.data  }});
          this.editar(this.id)
        }
        this.errores=[];
        this.submitted = false;
      })
      .catch(err =>{
          this.submitted = false;
          this.errores=err.response.data.errors;
      });
    },
    actualizar() {
      for(let i = 0 ; i < this.items.length ; i++){
        this.items[i].index=i;
      }
      axiosIns.put('/admin/galeria/'+this.id,{
        'titulo':this.titulo,
        'descripcion':this.descripcion,
        'published_at': this.published_at,
        'items':this.items,
      })
      .then(res => {
        this.$bvToast.toast('Exito', {
          title: 'Galeria de guardo con exito',
          variant:'primary',
          solid: false,
        })
        if (this.guardarSalir) {
          this.$router.push({ name: "galeria" });
        }else{
          this.editar(this.id);
        }
        this.errores=[];
        this.submitted = false;
      })
      .catch(err =>{
          this.submitted = false;
          this.errores=err.response.data.errors;
      });
    },
    editar(id){
      this.id=id;
      axiosIns.get('/admin/galeria/'+this.id)
      .then(res => {
        this.titulo=res.data.titulo;
        this.descripcion=res.data.descripcion;
        this.published_at=res.data.published_at;
        this.items=res.data.galeria_item.map(g => ({url: g.imagen, titulo:g.titulo, index:g.index}));
        //this.items=res.data.galeriaItem;
      })
      .catch(err =>{

      });
    },
    limpiar(){
      this.date();
      this.id=null;
      this.titulo='';
      this.descripcion='';
      this.items=[];
    }
  },
  mounted(){
    if (this.$route.params.id) {
      this.editar(this.$route.params.id);
    }   
  }
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/libs/quill.scss';

.ql-container.ql-snow {
      border: 0;
  }
</style>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>